import { FaPassport, FaFileAlt, FaIdCard } from "react-icons/fa"; // Import necessary icons
import React from "react";
import "../../styles/immigrationStatus.css";
const ImmigrationStatusDocuments = () => {
  return (
    <div
      className="immigration-status-container"
      data-aos="fade-up"
      data-aos-delay="200"
    >
      <h3 className="immigration-status-title">Immigration Status Documents</h3>
      <div className="immigration-status-content">
        <p className="immigration-status-intro">
          To confirm your and your partner's immigration status:
        </p>
        <div className="immigration-status-list">
          <div className="immigration-status-item">
            <FaPassport className="immigration-status-icon" />{" "}
            {/* Icon for passport */}
            <strong>Passport :</strong>
            <ul>
              <li>Your current passport and your partner’s passport.</li>
            </ul>
          </div>

          <div className="immigration-status-item">
            <FaFileAlt className="immigration-status-icon" />{" "}
            {/* Icon for previous immigration documents */}
            <strong>Previous Immigration Documents :</strong>
            <ul>
              <li>Any previous visas or immigration stamps.</li>
            </ul>
          </div>

          <div className="immigration-status-item">
            <FaIdCard className="immigration-status-icon" />{" "}
            {/* Icon for BRP */}
            <strong>Biometric Residence Permit (BRP) :</strong>
            <ul>
              <li>If applicable, your BRP or your partner’s BRP.</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImmigrationStatusDocuments;
