import { FaFileAlt, FaCertificate, FaBaby, FaIdCard } from "react-icons/fa";
import "../../styles/additional.css";
const AdditionalRequirements = () => (
  <div
    className="additional-requirements-container card my-5"
    data-aos="fade-up"
    data-aos-delay="200"
  >
    <h3 className="additional-requirements-title mx-5 my-5">
      Additional Requirements
    </h3>
    <div className="additional-requirements-content">
      <p className="additional-requirements-intro mx-5">
        Depending on your circumstances, you might need:
      </p>
      <div className="additional-requirements-list">
        <div className="additional-requirements-item">
          <FaFileAlt className="additional-requirements-icon" />
          <strong>Divorce Decree Absolute :</strong>
          <ul>
            <li>
              If either partner has been previously married or in a civil
              partnership, provide the divorce decree absolute or dissolution
              certificate.
            </li>
          </ul>
        </div>
        <div className="additional-requirements-item">
          <FaCertificate className="additional-requirements-icon" />
          <strong>Death Certificate :</strong>
          <ul>
            <li>
              If a previous spouse or partner has passed away, provide the death
              certificate.
            </li>
          </ul>
        </div>
        <div className="additional-requirements-item">
          <FaBaby className="additional-requirements-icon" />
          <strong>Children’s Documents :</strong>
          <ul>
            <li>
              Birth certificates of any dependent children included in the
              application, showing the parents' names.
            </li>
          </ul>
        </div>
        <div className="additional-requirements-item">
          <FaIdCard className="additional-requirements-icon" />
          <strong>Sponsor’s Proof of Status :</strong>
          <ul>
            <li>
              If your partner is a British citizen, provide their birth
              certificate or naturalization certificate.
            </li>
            <li>
              If your partner has settled status, provide proof such as a BRP,
              settled status document, or indefinite leave to remain (ILR)
              stamp.
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
);

export default AdditionalRequirements;
