import React from "react";
import "../../styles/categoryF.css"; // Import the CSS file

const CategoryF = () => {
  return (
    <div
      className="category-f-container my-5" // Updated class name
      id="category-f"
      data-aos="fade-up"
      data-aos-delay="200"
    >
      <h3 className="category-f-title">
        Category F: Self-Employment Income (Last Financial Year)
      </h3>
      <p className="category-f-subtitle mx-5">
        For income from self-employment as a sole trader, partner, or
        franchisee:
      </p>
      <div className="category-f-cards">
        <div className="category-f-card">
          <div className="category-f-card-number">01</div>
          <div className="category-f-card-content">
            <strong>Tax Return :</strong>
            <ul>
              <li>
                The most recent tax return (SA302) and corresponding tax year
                overview from HMRC.
              </li>
            </ul>
          </div>
        </div>
        <div className="category-f-card">
          <div className="category-f-card-number">02</div>
          <div className="category-f-card-content">
            <strong>Audited Accounts :</strong>
            <ul>
              <li>
                Audited or unaudited accounts with an accountant’s certificate
                of confirmation.
              </li>
            </ul>
          </div>
        </div>
        <div className="category-f-card">
          <div className="category-f-card-number">03</div>
          <div className="category-f-card-content">
            <strong>Business Bank Statements :</strong>
            <ul>
              <li>
                Statements for the business covering the last financial year.
              </li>
            </ul>
          </div>
        </div>
        <div className="category-f-card">
          <div className="category-f-card-number">04</div>
          <div className="category-f-card-content">
            <strong>Invoices and Receipts :</strong>
            <ul>
              <li>Documents showing the income generated by the business.</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoryF;
