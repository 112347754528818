import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css"; // Import AOS styles
import Swiper from "swiper";
import "swiper/swiper-bundle.css"; // Import Swiper styles
import GLightbox from "glightbox";
import "glightbox/dist/css/glightbox.min.css"; // Import GLightbox styles
import Isotope from "isotope-layout";
import imagesLoaded from "imagesloaded";
import "./App.css"; // Assuming your CSS file is in the same directory as App.js
import Header from "./components/header";
import Footer from "./components/footer";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/home";
import ScrollToTop from "./components/scroll-to-top";
import VisaRequirements from "./pages/visa-requirements";
import Guarantee from "./pages/guarantee";
import Faqs from "./pages/faqs";

const App = () => {
  useEffect(() => {
    // Apply .scrolled class to the body as the page is scrolled down
    const toggleScrolled = () => {
      const selectBody = document.querySelector("body");
      const selectHeader = document.querySelector("#header");
      if (
        !selectHeader.classList.contains("scroll-up-sticky") &&
        !selectHeader.classList.contains("sticky-top") &&
        !selectHeader.classList.contains("fixed-top")
      )
        return;
      window.scrollY > 100
        ? selectBody.classList.add("scrolled")
        : selectBody.classList.remove("scrolled");
    };

    document.addEventListener("scroll", toggleScrolled);
    window.addEventListener("load", toggleScrolled);

    // Mobile nav toggle
    const mobileNavToggleBtn = document.querySelector(".mobile-nav-toggle");
    const mobileNavToogle = () => {
      document.querySelector("body").classList.toggle("mobile-nav-active");
      mobileNavToggleBtn.classList.toggle("bi-list");
      mobileNavToggleBtn.classList.toggle("bi-x");
    };
    mobileNavToggleBtn.addEventListener("click", mobileNavToogle);

    // Hide mobile nav on same-page/hash links
    document.querySelectorAll("#navmenu a").forEach((navmenu) => {
      navmenu.addEventListener("click", () => {
        if (document.querySelector(".mobile-nav-active")) {
          mobileNavToogle();
        }
      });
    });

    // Toggle mobile nav dropdowns
    document
      .querySelectorAll(".navmenu .toggle-dropdown")
      .forEach((navmenu) => {
        navmenu.addEventListener("click", function (e) {
          if (document.querySelector(".mobile-nav-active")) {
            e.preventDefault();
            this.parentNode.classList.toggle("active");
            this.parentNode.nextElementSibling.classList.toggle(
              "dropdown-active"
            );
            e.stopImmediatePropagation();
          }
        });
      });

    // Preloader
    const preloader = document.querySelector("#preloader");
    if (preloader) {
      window.addEventListener("load", () => {
        preloader.remove();
      });
    }

    // Animation on scroll function and init
    const aosInit = () => {
      AOS.init({
        duration: 600,
        easing: "ease-in-out",
        once: true,
        mirror: false,
      });
    };
    window.addEventListener("load", aosInit);

    // Init swiper sliders
    const initSwiper = () => {
      document.querySelectorAll(".swiper").forEach(function (swiper) {
        let config = JSON.parse(
          swiper.querySelector(".swiper-config").innerHTML.trim()
        );
        new Swiper(swiper, config);
      });
    };
    window.addEventListener("load", initSwiper);

    // Initiate glightbox
    const glightbox = GLightbox({
      selector: ".glightbox",
    });

    // Init isotope layout and filters
    document
      .querySelectorAll(".isotope-layout")
      .forEach(function (isotopeItem) {
        let layout = isotopeItem.getAttribute("data-layout") ?? "masonry";
        let filter = isotopeItem.getAttribute("data-default-filter") ?? "*";
        let sort = isotopeItem.getAttribute("data-sort") ?? "original-order";

        let initIsotope;
        imagesLoaded(
          isotopeItem.querySelector(".isotope-container"),
          function () {
            initIsotope = new Isotope(
              isotopeItem.querySelector(".isotope-container"),
              {
                itemSelector: ".isotope-item",
                layoutMode: layout,
                filter: filter,
                sortBy: sort,
              }
            );
          }
        );

        isotopeItem
          .querySelectorAll(".isotope-filters li")
          .forEach(function (filters) {
            filters.addEventListener(
              "click",
              function () {
                isotopeItem
                  .querySelector(".isotope-filters .filter-active")
                  .classList.remove("filter-active");
                this.classList.add("filter-active");
                initIsotope.arrange({
                  filter: this.getAttribute("data-filter"),
                });
                if (typeof aosInit === "function") {
                  aosInit();
                }
              },
              false
            );
          });
      });

    // Correct scrolling position upon page load for URLs containing hash links.
    window.addEventListener("load", function (e) {
      if (window.location.hash) {
        if (document.querySelector(window.location.hash)) {
          setTimeout(() => {
            let section = document.querySelector(window.location.hash);
            let scrollMarginTop = getComputedStyle(section).scrollMarginTop;
            window.scrollTo({
              top: section.offsetTop - parseInt(scrollMarginTop),
              behavior: "smooth",
            });
          }, 100);
        }
      }
    });

    // Navmenu Scrollspy
    let navmenulinks = document.querySelectorAll(".navmenu a");
    const navmenuScrollspy = () => {
      navmenulinks.forEach((navmenulink) => {
        if (!navmenulink.hash) return;
        let section = document.querySelector(navmenulink.hash);
        if (!section) return;
        let position = window.scrollY + 200;
        if (
          position >= section.offsetTop &&
          position <= section.offsetTop + section.offsetHeight
        ) {
          document
            .querySelectorAll(".navmenu a.active")
            .forEach((link) => link.classList.remove("active"));
          navmenulink.classList.add("active");
        } else {
          navmenulink.classList.remove("active");
        }
      });
    };
    window.addEventListener("load", navmenuScrollspy);
    document.addEventListener("scroll", navmenuScrollspy);

    // Clean-up function for event listeners
    return () => {
      document.removeEventListener("scroll", toggleScrolled);
      mobileNavToggleBtn.removeEventListener("click", mobileNavToogle);
      document.querySelectorAll("#navmenu a").forEach((navmenu) => {
        navmenu.removeEventListener("click", () => {
          if (document.querySelector(".mobile-nav-active")) {
            mobileNavToogle();
          }
        });
      });
      document
        .querySelectorAll(".navmenu .toggle-dropdown")
        .forEach((navmenu) => {
          navmenu.removeEventListener("click", function (e) {
            if (document.querySelector(".mobile-nav-active")) {
              e.preventDefault();
              this.parentNode.classList.toggle("active");
              this.parentNode.nextElementSibling.classList.toggle(
                "dropdown-active"
              );
              e.stopImmediatePropagation();
            }
          });
        });
      // window.removeEventListener('load', toggleScrollTop);
      // document.removeEventListener('scroll', toggleScrollTop);
      window.removeEventListener("load", aosInit);
      window.removeEventListener("load", initSwiper);
      window.removeEventListener("load", function (e) {
        if (window.location.hash) {
          if (document.querySelector(window.location.hash)) {
            setTimeout(() => {
              let section = document.querySelector(window.location.hash);
              let scrollMarginTop = getComputedStyle(section).scrollMarginTop;
              window.scrollTo({
                top: section.offsetTop - parseInt(scrollMarginTop),
                behavior: "smooth",
              });
            }, 100);
          }
        }
      });
      document.removeEventListener("scroll", navmenuScrollspy);
    };
  }, []);

  return (
    <Router>
      <body className="index-page">
        <Header />
        <main className="main">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/visa-requirements" element={<VisaRequirements />} />
            <Route path="/guarantee" element={<Guarantee />} />
            <Route path="/faqs" element={<Faqs />} />
            {/* <Route path="/not-born-in-uk" element={<NotBornInUk />} />
            <Route path="/double-descent" element={<DoubleDecent />} />
            <Route path="/born-in-uk" element={<BornInUk />} /> */}
          </Routes>
        </main>
        <Footer />
        <ScrollToTop />
      </body>
    </Router>
  );
};

export default App;
