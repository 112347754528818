import React from "react";
import { FaCheckCircle, FaInfoCircle } from "react-icons/fa";
import "./index.css";
import {
  FaHandHoldingHeart,
  FaShieldAlt,
  FaMedkit,
  FaHeart,
  FaPencilAlt,
  FaWheelchair,
  FaGlasses,
  FaFileAlt,
  FaBolt,
  FaStar,
  FaCogs,
} from "react-icons/fa"; // Import different icons
import {
  FaDollarSign,
  FaBriefcase,
  FaPiggyBank,
  FaRegClock,
} from "react-icons/fa";

const MinimumIncomeRequirement = () => {
  const benefits = [
    { text: "Disability Living Allowance", icon: <FaWheelchair /> },
    { text: "Severe Disablement Allowance", icon: <FaShieldAlt /> },
    { text: "Industrial Injury Disablement Benefit", icon: <FaMedkit /> },
    { text: "Attendance Allowance", icon: <FaHeart /> },
    { text: "Pension Age Disability Payment (Scotland)", icon: <FaGlasses /> },
    { text: "Carer’s Allowance", icon: <FaHandHoldingHeart /> },
    { text: "Personal Independence Payment", icon: <FaPencilAlt /> },
    { text: "Adult Disability Payment (Scotland)", icon: <FaBolt /> },
    {
      text: "Armed Forces Independence Payment or Guaranteed Income Payment",
      icon: <FaStar />,
    },
    {
      text: "Constant Attendance Allowance, Mobility Supplement, or War Disablement Pension under the War Pensions Scheme",
      icon: <FaCogs />,
    },
  ];
  return (
    <div
      className="requirements-card"
      id="minimum-income"
      data-aos="fade-up"
      data-aos-delay="200"
    >
      <h3 className="requirements-title mb-3">
        Minimum Income Requirement (MIR)
      </h3>

      <section className="financial-thresholds">
        <div className="threshold-header">
          <h5 className="threshold-title">
            Financial Thresholds Effective from April 11, 2024
          </h5>
          <p className="threshold-description">
            New applicants must meet the following financial thresholds:
          </p>
        </div>

        <div className="threshold-details">
          <div className="threshold-item">
            <div className="threshold-icon">
              <FaCheckCircle size={24} color="#002f9e" />
            </div>
            <div className="threshold-content">
              <h6 className="threshold-subtitle">Standard Threshold</h6>
              <p className="threshold-text">
                £29,000: For those applying as a partner (including fiancé(e),
                proposed civil partner), or for permission to stay with a new
                partner.
              </p>
            </div>
          </div>

          <div className="threshold-item">
            <div className="threshold-icon">
              <FaCheckCircle size={24} color="#002f9e" />
            </div>
            <div className="threshold-content">
              <h6 className="threshold-subtitle">Transitional Arrangements</h6>
              <p className="threshold-text">
                Applicants who applied before April 11, 2024, and are continuing
                their application process with the same partner will follow the
                previous threshold of £18,600, with an additional income
                requirement of £3,800 for the first child and £2,400 for each
                subsequent child. The child component is capped at £29,000.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="maintenance-requirement">
        <div className="maintenance-header">
          <h5 className="maintenance-title">
            Adequate Maintenance Requirement
          </h5>
          <p className="maintenance-description">
            If your partner receives specified benefits, you must meet the
            adequate maintenance requirement. This means demonstrating that you
            can maintain and accommodate yourselves without relying on
            additional public funds.
          </p>
        </div>

        <div className="maintenance-details">
          <div className="maintenance-item">
            <div className="maintenance-icon">
              <FaInfoCircle size={24} color="#002f9e" />
            </div>
            <div className="maintenance-content">
              <h6 className="maintenance-subtitle">Requirement Overview</h6>
              <p className="maintenance-text">
                Demonstrate self-sufficiency by proving that you can support
                yourself and your partner without needing public funds.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="specified-benefits">
        <div className="benefits-header">
          <h5 className="benefits-title">Specified Benefits Include:</h5>
        </div>
        <div className="benefits-grid">
          {benefits.map((benefit, index) => (
            <div className="benefit-item" key={index}>
              <div className="benefit-icon">{benefit.icon}</div>
              <span className="benefit-text">{benefit.text}</span>
            </div>
          ))}
        </div>
      </section>

      <section className="income-sources">
        <div className="income-header">
          <h5 className="income-title">Sources of Income</h5>
          <p className="income-intro">
            The financial requirement can be met through various sources,
            categorized as follows:
          </p>
        </div>

        <div className="income-categories">
          <div className="income-item">
            <div className="income-icon">
              <FaBriefcase size={24} color="white" />
            </div>
            <div className="income-content">
              <h6 className="income-category-title">Employment Income</h6>
              <p className="income-description">
                Income from salaried or non-salaried employment of the partner
                or applicant if they have permission to work.
              </p>
            </div>
          </div>

          <div className="income-item">
            <div className="income-icon">
              <FaDollarSign size={24} color="white" />
            </div>
            <div className="income-content">
              <h6 className="income-category-title">Non-Employment Income</h6>
              <p className="income-description">
                Includes rental income, dividends from shares, etc.
              </p>
            </div>
          </div>

          <div className="income-item">
            <div className="income-icon">
              <FaPiggyBank size={24} color="white" />
            </div>
            <div className="income-content">
              <h6 className="income-category-title">Cash Savings</h6>
              <p className="income-description">
                Savings above £16,000 held for at least six months.
              </p>
            </div>
          </div>

          <div className="income-item">
            <div className="income-icon">
              <FaPencilAlt size={24} color="white" />
            </div>
            <div className="income-content">
              <h6 className="income-category-title">Pension Income</h6>
              <p className="income-description">
                State, occupational, or private pensions.
              </p>
            </div>
          </div>

          <div className="income-item">
            <div className="income-icon">
              <FaRegClock size={24} color="white" />
            </div>
            <div className="income-content">
              <h6 className="income-category-title">Self-Employment Income</h6>
              <p className="income-description">
                Income from self-employment or as a director/employee of a
                specified limited company.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="document-section">
        <div className="document-content">
          <div className="document-text">
            <h6 className="document-title">
              Essential Documents for Your Application:
            </h6>
            <p className="document-description">
              Ensure you have the following documents ready to meet the
              financial requirements for a Spouse or Partner Visa.
            </p>
          </div>
          <div className="document-image">
            <img src="/assets/img/document.jpg" alt="Documents" />
          </div>
        </div>
      </section>
    </div>
  );
};

export default MinimumIncomeRequirement;
