import React from "react";
import "../../styles/categoryC.css"; // Import the CSS file

const CategoryC = () => {
  return (
    <div
      className="category-c-container my-5" // Updated class name
      id="category-c"
      data-aos="fade-up"
      data-aos-delay="200"
    >
      <h3 className="category-c-title">Category C: Non-Employment Income</h3>
      <p className="category-c-subtitle mx-5">
        If relying on non-employment income such as property rental income or
        dividends:
      </p>
      <div className="category-c-cards">
        <div className="category-c-card">
          <div className="category-c-card-number">01</div>
          <div className="category-c-card-content">
            <strong>Evidence of Ownership :</strong>
            <ul>
              <li>
                Documents proving ownership of the asset generating the income
                (e.g., property deeds or share certificates).
              </li>
            </ul>
          </div>
        </div>
        <div className="category-c-card">
          <div className="category-c-card-number">02</div>
          <div className="category-c-card-content">
            <strong>Income Proof :</strong>
            <ul>
              <li>
                Rental income: Tenancy agreements and bank statements showing
                receipt of rental income.
              </li>
              <li>
                Dividends: Dividend vouchers and bank statements showing receipt
                of dividends.
              </li>
            </ul>
          </div>
        </div>
        <div className="category-c-card">
          <div className="category-c-card-number">03</div>
          <div className="category-c-card-content">
            <strong>Tax Documents :</strong>
            <ul>
              <li>
                Tax returns or HMRC documents showing the income received.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoryC;
