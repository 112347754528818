import React, { useEffect, useState } from "react";
import Aos from "aos";
import "aos/dist/aos.css";

const Faqs = () => {
  const [activeKey, setActiveKey] = useState(null);

  const handleToggle = (key) => {
    setActiveKey(activeKey === key ? null : key);
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    Aos.init({ duration: 800, easing: "slide", once: true });
    return () => Aos.refresh();
  }, []);

  const faqSections = [
    {
      title: "General Questions",
      id: "General1",
      faqs: [
        {
          question: "What services does UK Spouse and Partner Visa offer?",
          answer: `UK Spouse and Partner Visa provides a range of services including initial consultation, document preparation, application submission, and appeals and refusals support. We specialize in helping clients apply for UK spouse and partner visas with personalized guidance and support throughout the entire process.`,
          targetId: "GeneralFaq1",
        },
        {
          question: "How can I contact UK Spouse and Partner Visa?",
          answer: `You can contact us via phone at 02031379097, Whatsapp at +447984713967, Email at info@ukspouseandpartnervisa.com, or visit us at our office located at 518 Roman Road, Bow, London E3 5ES.`,
          targetId: "GeneralFaq2",
        },
      ],
    },
    {
      title: "Application Process",
      id: "ApplicationProcess1",
      faqs: [
        {
          question: "What is a spouse or partner visa?",
          answer: `A spouse or partner visa allows the non-UK partner of a British citizen or a person settled in the UK to join them in the UK. This visa is available to spouses, civil partners, unmarried partners, and fiancé(e)s/proposed civil partners.`,
          targetId: "ApplicationProcessFaq1",
        },
        {
          question: "How do I apply for a UK spouse or partner visa?",
          answer: `To apply, you must meet several requirements including proving your relationship, meeting financial and accommodation requirements, and providing evidence of your English language ability. The application process involves submitting an online application form, supporting documents, and attending a biometric appointment.`,
          targetId: "ApplicationProcessFaq2",
        },
        {
          question:
            "What are the eligibility requirements for a spouse or partner visa?",
          answer: [
            "Both partners must be over 18 years old.",
            "You must be in a genuine and subsisting relationship.",
            "You must intend to live together permanently in the UK.",
            "You must meet the financial requirements.",
            "You must have adequate accommodation.",
            "You must meet the English language requirement.",
          ],
          targetId: "ApplicationProcessFaq3",
        },
      ],
    },
    {
      title: "Financial Requirements",
      id: "FinancialRequirements1",
      faqs: [
        {
          question:
            "What is the minimum income requirement for a spouse or partner visa?",
          answer: `From April 11, 2024, the minimum income requirement is £29,000. For applications submitted before this date, the previous threshold of £18,600 applies, with additional income requirements for children.`,
          targetId: "FinancialRequirementsFaq1",
        },
        {
          question:
            "What sources of income can be used to meet the financial requirement?",
          answer: `You can use employment income, non-employment income (e.g., rental income, dividends), cash savings above £16,000, pension income, and income from self-employment or as a director of a specified limited company.`,
          targetId: "FinancialRequirementsFaq2",
        },
        {
          question: "What if my partner receives public benefits?",
          answer: `If your partner receives specified benefits, you must meet the adequate maintenance requirement instead of the minimum income requirement. This involves proving that you can maintain and accommodate yourselves without additional public funds.`,
          targetId: "FinancialRequirementsFaq3",
        },
      ],
    },
    {
      title: "Relationship Requirements",
      id: "RelationshipRequirements1",
      faqs: [
        {
          question: "What documents do I need to prove my relationship?",
          answer: [
            "Marriage or civil partnership certificate.",
            "Joint utility bills, tenancy agreements, or mortgage documents.",
            "Bank statements showing joint finances.",
            "Photographs of your relationship.",
            "Correspondence between you and your partner.",
            "Statements from friends and family attesting to your relationship.",
          ],
          targetId: "RelationshipRequirementsFaq1",
        },
        {
          question: "Can I apply if I am engaged to my partner?",
          answer: `Yes, you can apply for a fiancé(e) or proposed civil partner visa if you intend to marry or enter into a civil partnership within six months of arriving in the UK. You must prove that you and your partner have met and plan to live together permanently.`,
          targetId: "RelationshipRequirementsFaq2",
        },
      ],
    },
    {
      title: "Accommodation Requirements",
      id: "AccommodationRequirements1",
      faqs: [
        {
          question: "What constitutes adequate accommodation?",
          answer: `Adequate accommodation means that you and your partner will have a place to live in the UK that meets public health regulations and is not overcrowded. You must provide evidence such as tenancy agreements or mortgage statements.`,
          targetId: "AccommodationRequirementsFaq1",
        },
      ],
    },
    {
      title: "English Language Requirement",
      id: "EnglishLanguageRequirement1",
      faqs: [
        {
          question: "Do I need to take an English language test?",
          answer: `Yes, unless you have a degree taught in English or are from a majority English-speaking country, you need to pass an approved English language test at the required level.`,
          targetId: "EnglishLanguageRequirementFaq1",
        },
        {
          question:
            "What documents can I provide to meet the English language requirement?",
          answer: [
            "English language test certificate from an approved provider.",
            "Degree certificate or academic transcript if your degree was taught in English and is recognized by UK NARIC."
          ],
          targetId: "EnglishLanguageRequirementFaq2",
        },
      ],
    },
    {
      title: "Immigration Status",
      id: "ImmigrationStatus1",
      faqs: [
        {
          question: "What immigration documents do I need to provide?",
          answer: [
            "Your current passport and your partner’s passport.",
            "Any previous visas or immigration stamps.",
            "Biometric Residence Permit (BRP), if applicable."
          ],
          targetId: "ImmigrationStatusFaq1",
        },
      ],
    },
    {
      title: "Application Fees and Processing Time",
      id: "ApplicationFeesProcessingTime1",
      faqs: [
        {
          question:
            "How much does it cost to apply for a spouse or partner visa?",
          answer: `The cost includes the visa application fee and the Immigration Health Surcharge (IHS). The exact amount can vary, so it is best to check the current fees on the official UK government website.`,
          targetId: "ApplicationFeesProcessingTimeFaq1",
        },
        {
          question: "How long does the visa application process take?",
          answer: `The processing time can vary, but it generally takes around 12 weeks from the date of your biometrics appointment. Priority services are available for faster processing at an additional cost.`,
          targetId: "ApplicationFeesProcessingTimeFaq2",
        },
      ],
    },
    {
      title: "Refusals and Appeals",
      id: "RefusalsAndAppeals1",
      faqs: [
        {
          question: "What should I do if my visa application is refused?",
          answer: `If your visa application is refused, you will receive a refusal notice detailing the reasons. You may be able to appeal the decision or apply for an administrative review depending on the circumstances. It’s advisable to seek professional advice to understand your options.`,
          targetId: "RefusalsAndAppealsFaq1",
        },
        {
          question: "How can I appeal a visa refusal?",
          answer: `To appeal a visa refusal, you need to follow the instructions in your refusal notice. Appeals are typically lodged with the First-tier Tribunal (Immigration and Asylum Chamber). The process involves submitting an appeal form and supporting documents.`,
          targetId: "RefusalsAndAppealsFaq2",
        },
      ],
    },
    {
      title: "Additional Information",
      id: "AdditionalInformation1",
      faqs: [
        {
          question: "Can I work on a spouse or partner visa?",
          answer: `Yes, if you are granted a spouse or partner visa, you will be allowed to work in the UK.`,
          targetId: "AdditionalInformationFaq1",
        },
        {
          question: "Can I include my children in my application?",
          answer: `Yes, you can include children as dependents in your application if they are under 18 or were under 18 when first granted leave and are not living an independent life.`,
          targetId: "AdditionalInformationFaq2",
        },
        {
          question:
            "What is the income requirement for including children in the application?",
          answer: `For each dependent child, the minimum income requirement increases. You will need an additional £3,800 for the first child and £2,400 for each subsequent child.`,
          targetId: "AdditionalInformationFaq3",
        },
        {
          question: "When can I apply for indefinite leave to remain (ILR)?",
          answer: `The earliest you can apply for ILR is after living in the UK for five years continuously with permission to stay as a partner. You cannot count any time spent in the UK on a fiancé(e) or proposed civil partner visa.`,
          targetId: "AdditionalInformationFaq4",
        },
      ],
    },
  ];

  return (
    <section id="faq" className="faq-section section">
      <div className="container section-title" data-aos="fade-up">
        <span>Frequently Asked Questions</span>
        <h2>Frequently Asked Questions</h2>
      </div>
      <div className="container">
        <div className="row">
          {faqSections.map((section, sectionIndex) => (
            <div key={sectionIndex} className="col-12">
              <h3 className="text-center my-5">{section.title}</h3>
              <div className="accordion-container">
                <div
                  className="accordion accordion-flush"
                  id={`section-${sectionIndex}`}
                >
                  {section.faqs.map((faq) => (
                    <div className="accordion-item" key={faq.targetId}>
                      <h2 className="accordion-header">
                        <button
                          className={`accordion-button ${
                            activeKey === faq.targetId ? "" : "collapsed"
                          }`}
                          type="button"
                          onClick={() => handleToggle(faq.targetId)}
                          aria-expanded={activeKey === faq.targetId}
                          aria-controls={`flush-${section.title.replace(
                            /\s+/g,
                            ""
                          )}-${faq.targetId}`}
                        >
                          {/* {activeKey === faq.targetId ? (
                            <FaMinus className="accordion-icon" />
                          ) : (
                            <FaPlus className="accordion-icon" />
                          )} */}
                          {faq.question}
                        </button>
                      </h2>
                      <div
                        id={`flush-${section.title.replace(/\s+/g, "")}-${
                          faq.targetId
                        }`}
                        className={`accordion-collapse collapse ${
                          activeKey === faq.targetId ? "show" : ""
                        }`}
                        data-bs-parent={`#section-${sectionIndex}`}
                      >
                        <div className="accordion-body">
                          {" "}
                          {Array.isArray(faq.answer) ? (
                            <ul>
                              {faq.answer.map((item, index) => (
                                <li key={index}>{item}</li>
                              ))}
                            </ul>
                          ) : (
                            <p>{faq.answer}</p>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Faqs;
