import React from "react";
import "../../styles/categoryG.css"; // Import the CSS file

const CategoryG = () => {
  return (
    <div
      className="category-g-container my-5" // Updated class name
      id="category-g"
      data-aos="fade-up"
      data-aos-delay="200"
    >
      <h3 className="category-g-title">
        Category G: Income as a Director or Emp loyee of a Specified Limited
        Company
      </h3>
      <p className="category-g-subtitle mx-5">
        For income from a company of which you are a director or employee:
      </p>
      <div className="category-g-cards">
        <div className="category-g-card">
          <div className="category-g-card-number">01</div>
          <div className="category-g-card-content">
            <strong>Company Accounts :</strong>
            <ul>
              <li>Full company accounts for the most recent financial year.</li>
            </ul>
          </div>
        </div>
        <div className="category-g-card">
          <div className="category-g-card-number">02</div>
          <div className="category-g-card-content">
            <strong>Tax Return :</strong>
            <ul>
              <li>
                Corporation tax return (CT600) for the last financial year.
              </li>
            </ul>
          </div>
        </div>
        <div className="category-g-card">
          <div className="category-g-card-number">03</div>
          <div className="category-g-card-content">
            <strong>Payslips and Dividends :</strong>
            <ul>
              <li>
                Payslips and dividend vouchers covering the last financial year.
              </li>
            </ul>
          </div>
        </div>
        <div className="category-g-card">
          <div className="category-g-card-number">04</div>
          <div className="category-g-card-content">
            <strong>Personal and Business Bank Statements :</strong>
            <ul>
              <li>Statements showing the payment of salary and dividends.</li>
            </ul>
          </div>
        </div>
      </div>

      {/* Combining income sources */}
      <h3 className="category-g-combine-title mx-5 my-3">
        Combining Income Sources
      </h3>
      <p className="category-g-combine-subtitle mx-5">
        If combining different sources of income, ensure that you provide all
        the required documents for each category of income you are relying on.
      </p>
    </div>
  );
};

export default CategoryG;
