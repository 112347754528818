import React from "react";
import { FaFileAlt, FaHome, FaClipboardCheck, FaBolt } from "react-icons/fa"; // Import icons
import "../../styles/accommodationRequirements.css";
const Acommodation = () => {
  return (
    <div
      className="accommodation-requirements-container my-5"
      id="accommodation-requirements"
      data-aos="fade-up"
      data-aos-delay="200"
    >
      <h3 className="accommodation-requirements-title mx-5 my-5">
        Accommodation Requirements
      </h3>
      <div className="accommodation-requirements-content">
        <p className="accommodation-requirements-intro mx-5">
          To prove that you have adequate accommodation in the UK, you will
          need:
        </p>
        <ol className="accommodation-requirements-list mx-5">
          <li className="accommodation-requirements-item">
            <FaHome className="accommodation-icon" /> {/* Icon for home */}
            <strong>Tenancy Agreement or Mortgage Documents :</strong>
            <ul>
              <li>
                Lease agreement or mortgage statement showing your name and
                address.
              </li>
            </ul>
          </li>

          <li className="accommodation-requirements-item">
            <FaFileAlt className="accommodation-icon" />{" "}
            {/* Icon for documents */}
            <strong>Landlord’s Letter :</strong>
            <ul>
              <li>
                If you are renting, a letter from your landlord confirming that
                you are permitted to live in the property with your partner.
              </li>
            </ul>
          </li>

          <li className="accommodation-requirements-item">
            <FaClipboardCheck className="accommodation-icon" />{" "}
            {/* Icon for inspection */}
            <strong>Property Inspection Report :</strong>
            <ul>
              <li>
                If applicable, a report showing the property is not overcrowded
                and meets public health standards.
              </li>
            </ul>
          </li>

          <li className="accommodation-requirements-item">
            <FaBolt className="accommodation-icon" />{" "}
            {/* Icon for utility bills */}
            <strong>Utility Bills :</strong>
            <ul>
              <li>
                Utility bills in your name or jointly with your partner to prove
                residency.
              </li>
            </ul>
          </li>
        </ol>
      </div>
    </div>
  );
};

export default Acommodation;
