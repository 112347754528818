import { FaReceipt } from "react-icons/fa";
import '../../styles/healthSurcharge.css'
const HealthSurcharge = () => (
  <div
    className="health-surcharge-container card my-5"
    data-aos="fade-up"
    data-aos-delay="200"
  >
    <h3 className="health-surcharge-title mx-5 my-5">
      Health Surcharge and Application Fee Payment
    </h3>
    <div className="health-surcharge-content">
      <p className="health-surcharge-intro mx-5">
        To confirm that you have paid the required fees:
      </p>
      <div className="health-surcharge-list">
        <div className="health-surcharge-item">
          <FaReceipt className="health-surcharge-icon" />
          <strong>Payment Confirmation :</strong>
          <ul>
            <li>
              Receipt or confirmation of payment of the Immigration Health
              Surcharge (IHS) and application fee.
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
);

export default HealthSurcharge;
