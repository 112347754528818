import React from "react";
import "../../styles/categoryE.css"; // Import the CSS file

const CategoryE = () => {
  return (
    <div
      className="category-e-container my-5" // Updated class name
      id="category-e"
      data-aos="fade-up"
      data-aos-delay="200"
    >
      <h3 className="category-e-title">Category E: Pension Income</h3>
      <p className="category-e-subtitle mx-5">For pension income:</p>
      <div className="category-e-cards">
        <div className="category-e-card">
          <div className="category-e-card-number">01</div>
          <div className="category-e-card-content">
            <strong>Pension Statement :</strong>
            <ul>
              <li>
                An official pension statement showing the amount of pension
                received.
              </li>
            </ul>
          </div>
        </div>
        <div className="category-e-card">
          <div className="category-e-card-number">02</div>
          <div className="category-e-card-content">
            <strong>Bank Statements :</strong>
            <ul>
              <li>Personal bank statements showing the pension payments.</li>
            </ul>
          </div>
        </div>
        <div className="category-e-card">
          <div className="category-e-card-number">03</div>
          <div className="category-e-card-content">
            <strong>Pension Award Letter :</strong>
            <ul>
              <li>
                A letter from the pension provider confirming the details of the
                pension.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoryE;
