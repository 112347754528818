import React from "react";
import "../../styles/requirementSummary.css";
const RequiremtSummary = () => {
  return (
    <div
      class="summary-container card my-5"
      data-aos="fade-up"
      data-aos-delay="200"
    >
      <h3 class="summary-title mx-5 my-5">Summary</h3>
      <div class="summary-content mx-5">
        <p>
          Gathering the required documents is crucial for a successful spouse or
          partner visa application. Ensure all documents are original or
          certified copies and that translations are provided for any documents
          not in English. Organize the documents according to the categories
          above to make it easier for the caseworker to review your application.
        </p>
      </div>
    </div>
  );
};

export default RequiremtSummary;
