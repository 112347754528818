import React from "react";

const Services = () => {
  return (
    <section id="services" class="advertisers-service-sec pt-5 pb-5">
      <div class="container">
        <div class="row">
          {/* <!-- Section Title --> */}
          <div class="container section-title" data-aos="fade-up">
            <span>
              Services
              <br />
            </span>
            <h2>
              Services
              <br />
            </h2>
            <p>The service we offer covers the following in detail:</p>
          </div>
        </div>
        <div class="row mt-5 mt-md-4 row-cols-1 row-cols-sm-1 row-cols-md-3 justify-content-center">
          <div class="col">
            <div class="service-card">
              <div class="icon-wrapper">
                <i class="bi bi-list-columns-reverse"></i>
              </div>
              <h3>Initial Consultation</h3>
              <p>Assess your eligibility for a spouse or partner visa.</p>
              <p>
                Provide a comprehensive overview of the application process.
              </p>
              {/* <!-- <a href="not-born-in-uk.html" class="btn btn-outline btn-categories">Read More</a> --> */}
            </div>
          </div>
          <div class="col">
            <div class="service-card">
              <div class="icon-wrapper">
                <i class="bi bi-list-columns-reverse"></i>
              </div>
              <h3>Document Preparation</h3>
              <p>Assist in gathering and organizing all necessary documents.</p>
              <p>
                Ensure all documentation meets the Home Office requirements.
              </p>
              {/* <!-- <a href="double-descent.html" class="btn btn-outline btn-categories">Read More</a> --> */}
            </div>
          </div>
          <div class="col">
            <div class="service-card">
              <div class="icon-wrapper">
                <i class="bi bi-list-columns-reverse"></i>
              </div>
              <h3>Application Submission</h3>
              <p>Complete and submit your visa application on your behalf.</p>
              <p>
                Liaise with the Home Office to address any queries or issues.
              </p>
              {/* <!-- <a href="born-in-uk.html" class="btn btn-outline btn-categories">Read More</a> --> */}
            </div>
          </div>
        </div>
        <h6 class="text-center">
          We are so confident in our services that we offer a unique guarantee
          that no other firm can rival. Please see our guarantee page for more
          detail.
        </h6>
      </div>
    </section>
  );
};

export default Services;
