import React from "react";
import { Link, useLocation } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const Footer = () => {
  const location = useLocation();
  const currentPath = location.pathname;
  const currentHash = location.hash;

  // Function to determine if the link is active
  const isActive = (path, hash = "") => {
    return currentPath === path && currentHash === hash;
  };

  return (
    <footer id="footer" className="footer position-relative">
      <div className="container footer-top">
        <div className="row gy-4">
          <div className="col-lg-6 col-md-6">
            <div className="footer-about">
              <a href="/" className="logo sitename">
                SPOUSE AND PARTNERS VISA
              </a>
              <div className="footer-contact pt-3">
                <p>Hunter Stone Law, 518 Roman Road,</p>
                <p>London, E3 5ES</p>
                <p className="mt-3">
                  <strong>Phone:</strong> <span>+44 20 3137 9097</span>
                </p>
                <p>
                  <strong>Email:</strong>{" "}
                  <span>info@registeryourchildbritish.com</span>
                </p>
              </div>
              <div className="social-links d-flex mt-4">
                <a href="">
                  <i className="bi bi-twitter-x"></i>
                </a>
                <a href="">
                  <i className="bi bi-facebook"></i>
                </a>
                <a href="">
                  <i className="bi bi-instagram"></i>
                </a>
                <a href="">
                  <i className="bi bi-linkedin"></i>
                </a>
              </div>
            </div>
          </div>

          <div className="col-lg-2 col-md-3 footer-links">
            <h4>Useful Links</h4>
            <ul>
              <li>
                <HashLink
                  className={`nav-link ${
                    isActive("/", "#home") ? "active" : ""
                  }`}
                  to="/#home"
                >
                  Home
                </HashLink>
              </li>
              <li>
                <HashLink
                  className={`nav-link ${
                    isActive("/", "#about") ? "active" : ""
                  }`}
                  to="/#about"
                >
                  About
                </HashLink>
              </li>
              <li>
                <HashLink
                  className={`nav-link ${
                    isActive("/", "#services") ? "active" : ""
                  }`}
                  to="/#services"
                >
                  Services
                </HashLink>
              </li>
              <li>
                <Link
                  className={`nav-link ${
                    currentPath === "/visa-requirements" ? "active" : ""
                  }`}
                  to="/visa-requirements"
                >
                  Visa Requirements
                </Link>
              </li>
              <li>
                <Link
                  className={`nav-link ${
                    currentPath === "/guarantee" ? "active" : ""
                  }`}
                  to="/guarantee"
                >
                  Guarantee
                </Link>
              </li>
              <li>
                <Link
                  className={`nav-link ${
                    currentPath === "/faqs" ? "active" : ""
                  }`}
                  to="/faqs"
                >
                  FAQ
                </Link>
              </li>
              <li>
                <HashLink
                  className={`nav-link ${
                    isActive("/", "#contact") ? "active" : ""
                  }`}
                  to="/#contact"
                >
                  Contact
                </HashLink>
              </li>
            </ul>
          </div>

          <div className="col-lg-4 col-md-3 footer-links">
            <h4>Visa Requirements</h4>
            <ul>
              <li>
                <HashLink
                  className={`nav-link ${
                    isActive("/visa-requirements", "#category-a")
                      ? "active"
                      : ""
                  }`}
                  to="/visa-requirements#category-a"
                >
                  Category A
                </HashLink>
              </li>
              <li>
                <HashLink
                  className={`nav-link ${
                    isActive("/visa-requirements", "#category-b")
                      ? "active"
                      : ""
                  }`}
                  to="/visa-requirements#category-b"
                >
                  Category B
                </HashLink>
              </li>
              <li>
                <HashLink
                  className={`nav-link ${
                    isActive("/visa-requirements", "#category-c")
                      ? "active"
                      : ""
                  }`}
                  to="/visa-requirements#category-c"
                >
                  Category C
                </HashLink>
              </li>
              <li>
                <HashLink
                  className={`nav-link ${
                    isActive("/visa-requirements", "#category-d")
                      ? "active"
                      : ""
                  }`}
                  to="/visa-requirements#category-d"
                >
                  Category D
                </HashLink>
              </li>
              <li>
                <HashLink
                  className={`nav-link ${
                    isActive("/visa-requirements", "#category-e")
                      ? "active"
                      : ""
                  }`}
                  to="/visa-requirements#category-e"
                >
                  Category E
                </HashLink>
              </li>
              <li>
                <HashLink
                  className={`nav-link ${
                    isActive("/visa-requirements", "#category-f")
                      ? "active"
                      : ""
                  }`}
                  to="/visa-requirements#category-f"
                >
                  Category F
                </HashLink>
              </li>
              <li>
                <HashLink
                  className={`nav-link ${
                    isActive("/visa-requirements", "#category-g")
                      ? "active"
                      : ""
                  }`}
                  to="/visa-requirements#category-g"
                >
                  Category G
                </HashLink>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="container copyright text-center mt-4">
        <p>
          © <span>Copyright</span>{" "}
          <strong className="px-1 sitename">Spouse and Partners Visa</strong>{" "}
          <span>All Rights Reserved</span>
        </p>
        <div className="credits">
          <strong className="sitename">spouseandpartners.com</strong> is a
          collaboration between MK Squared Marketing and Hunter Stone Law.
        </div>
      </div>
    </footer>
  );
};

export default Footer;
