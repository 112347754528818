import React from "react";
import { FaCheck } from "react-icons/fa"; // Font Awesome for the "checked" icon
import "./index.css";
// Array for dynamic card data
const visaRequirements = [
  {
    title: "Relationship Proof",
    content: [
      "Evidence of a genuine and subsisting relationship.",
      "Marriage or civil partnership certificate.",
      "Proof of cohabitation and shared responsibilities.",
    ],
  },
  {
    title: "Financial Requirements",
    content: [
      "Meet the minimum income threshold or adequate maintenance requirement.",
      "Provide proof of employment, savings, or other income sources.",
    ],
  },
  {
    title: "Accommodation",
    content: [
      "Demonstrate adequate accommodation without additional recourse to public funds.",
    ],
  },
  {
    title: "English Language",
    content: [
      "Pass an approved English language test or provide proof of a degree taught in English.",
    ],
  },
  {
    title: "Immigration Status",
    content: [
      "Valid passports and previous immigration documents.",
      "Sponsor’s proof of settled status or British citizenship.",
    ],
  },
];

// Component for Visa Requirements Section
const VisaRequirementsSection = () => {
  return (
    <div
    //   className="card"
      id="understanding-visa-requirements"
      data-aos="fade-up"
      data-aos-delay="200"
    >
      <div className="visa-requirements-container">
        <div
          className="content-section"
          data-aos="fade-up"
          data-aos-delay="200"
        >
          <h3 className="section-title">
            Understanding Spouse and Partner Visa Requirements
          </h3>
          <div className="section-content">
            <p className="section-paragraph">
              To bring your spouse or partner to the UK, you must meet specific
              requirements set out in the Immigration Rules, particularly under
              Appendix FM. These rules ensure that applicants can financially
              support their partners without recourse to public funds and that
              they have suitable accommodation.
            </p>
            <p className="section-paragraph">
              Below is a comprehensive guide to help you navigate these
              requirements.
            </p>
            <p className="section-paragraph">
              To successfully apply for a UK spouse or partner visa, you must
              meet several key requirements. Once you instruct us, we will
              provide a comprehensive document list for you to prepare to ensure
              your application is successful.
            </p>
          </div>
        </div>
      </div>

      <div className="container">
        <p className="section-highlight">
          <strong>
            Meeting the Immigration Rules: Spouse and Partner Visa Requirements
          </strong>
        </p>
        <div className="row justify-content-center">
          {" "}
          {/* Center cards when fewer than 3 */}
          {visaRequirements.map((requirement, index) => (
            <div
              className="col-md-4 col-lg-4 my-3 d-flex justify-content-center"
              key={index}
            >
              {" "}
              {/* 3 cards per row */}
              <div className="d-flex align-items-center box-container p-3">
                <div className="icon-container d-flex justify-content-center align-items-center">
                  <FaCheck className="check-icon text-white" size={24} />
                </div>
                <div className="content-container ms-3">
                  <h5 className="font-weight-bold mb-2">{requirement.title}</h5>
                  {requirement.content.map((text, idx) => (
                    <p className="mb-1" key={idx}>
                      {text}
                    </p>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default VisaRequirementsSection;
