import React from "react";
import "../../styles/categoryD.css"; // Import the CSS file

const CategoryD = () => {
  return (
    <div
      className="category-d-container my-5" // Updated class name
      id="category-d"
      data-aos="fade-up"
      data-aos-delay="200"
    >
      <h3 className="category-d-title">Category D: Cash Savings</h3>
      <p className="category-d-subtitle mx-5">
        If using cash savings to meet the financial requirement:
      </p>
      <div className="category-d-cards">
        <div className="category-d-card">
          <div className="category-d-card-number">01</div>
          <div className="category-d-card-content">
            <strong>Bank Statements :</strong>
            <ul>
              <li>
                Statements showing the amount of savings held for at least 6
                months.
              </li>
            </ul>
          </div>
        </div>
        <div className="category-d-card">
          <div className="category-d-card-number">02</div>
          <div className="category-d-card-content">
            <strong>Declaration of Source of Savings :</strong>
            <ul>
              <li>
                A written declaration by the account holder(s) regarding the
                source of the savings.
              </li>
            </ul>
          </div>
        </div>
        <div className="category-d-card">
          <div className="category-d-card-number">03</div>
          <div className="category-d-card-content">
            <strong>Evidence of Transfer :</strong>
            <ul>
              <li>
                Documents showing the transfer of funds into the account if the
                savings have been transferred from another account.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoryD;
