import React from "react";
import "../../styles/categoryA.css";
const CategoryA = () => {
  return (
    <div
      className="card my-5"
      id="category-a"
      data-aos="fade-up"
      data-aos-delay="200"
    >
      <h3 className="category-title mx-5 my-5">
        Category A: Employment Income (Salaried and Non-Salaried)
      </h3>

      <p className="category-subtitle mx-5">
        If you are relying on income from employment, you must provide:
      </p>

      <div className="category-cards">
        {/* Card 1 */}
        <div className="category-card">
          <div className="card-number">01</div>
          <div className="card-content">
            <strong>Confirmation of Employment :</strong>
            <ul>
              <li>A letter from your employer(s) confirming employment.</li>
              <li>
                This letter should detail the job title, period of employment,
                salary, and type of employment (permanent, fixed-term, or
                agency).
              </li>
            </ul>
          </div>
        </div>

        {/* Card 2 */}
        <div className="category-card">
          <div className="card-number">02</div>
          <div className="card-content">
            <strong>Payslips :</strong>
            <ul>
              <li>Payslips covering at least the last 6 months.</li>
            </ul>
          </div>
        </div>

        {/* Card 3 */}
        <div className="category-card">
          <div className="card-number">03</div>
          <div className="card-content">
            <strong>Bank Statements :</strong>
            <ul>
              <li>
                Personal bank statements corresponding to the payslips, showing
                that the salary has been paid into an account in the name of the
                person or in the name of the person and their partner jointly.
              </li>
            </ul>
          </div>
        </div>

        {/* Card 4 */}
        <div className="category-card">
          <div className="card-number">04</div>
          <div className="card-content">
            <strong>Employment Contract :</strong>
            <ul>
              <li>A copy of the employment contract, if available.</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoryA;
