import React from "react";
import "../../styles/accommodation.css"; // Import the CSS file

const AccommodationRequirements = () => {
  return (
    <div
      className="accommodation-container"
      id="accommodation-requirements-1"
      data-aos="fade-up"
      data-aos-delay="200"
    >
      <h3 className="accommodation-title">Accommodation Requirements</h3>
      <div>
        <p className="accommodation-text">
          Applicants must also demonstrate that they have adequate accommodation
          for themselves, their partner, and any dependents. This includes
          ensuring the accommodation is not overcrowded and meets public health
          regulations.
        </p>
      </div>

      <h3 className="accommodation-exception-title">
        Exceptional Circumstances
      </h3>
      <div>
        <p className="accommodation-exception-text">
          In certain exceptional circumstances, where refusal of the application
          could breach Article 8 of the European Convention on Human Rights,
          other credible and reliable sources of income or funds may be
          considered to meet the financial requirement.
        </p>
        <p className="accommodation-documents">
          Documents Needed to Meet Other Requirements for a Spouse or Partner
          Visa
        </p>
      </div>
    </div>
  );
};

export default AccommodationRequirements;
