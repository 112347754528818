import React from "react";
import { FaCertificate } from "react-icons/fa";
import "../../styles/tuberculosis.css";

const Tuberculosis = () => {
  return (
    <div class="tb-test-container">
      <h3 class="tb-test-title mx-5 my-5">
        Tuberculosis (TB) Test Certificate
      </h3>
      <div class="tb-test-content">
        <p class="tb-test-intro mx-5">
          If you are from a country where TB testing is required:
        </p>
        <div class="tb-test-list">
          <div class="tb-test-item">
            <FaCertificate className="tb-test-icon" /> {/* React Icon */}
            <strong>TB Test Certificate :</strong>
            <ul>
              <li>A valid TB test certificate from an approved test center.</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tuberculosis;
