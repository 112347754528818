import React from "react";
import "../../styles/categoryB.css"; // Import the CSS file

const CategoryB = () => {
  return (
    <div
      className="category-b-container my-5" // Updated class name
      id="category-b"
      data-aos="fade-up"
      data-aos-delay="200"
    >
      <h3 className="category-b-title">
        Category B: Non-Salaried Employment or Self-Employment
      </h3>
      <p className="category-b-subtitle mx-5">
        If you are relying on income from employment, you must provide:
      </p>
      <div className="category-b-cards">
        <div className="category-b-card">
          <div className="category-b-card-number">01</div>
          <div className="category-b-card-content">
            <strong>Letter from Employer :</strong>
            <ul>
              <li>
                As in Category A, confirming employment details, salary, and
                duration.
              </li>
            </ul>
          </div>
        </div>
        <div className="category-b-card">
          <div className="category-b-card-number">02</div>
          <div className="category-b-card-content">
            <strong>Evidence of Earnings :</strong>
            <ul>
              <li>
                Payslips for the last 12 months or the entire period of
                employment if shorter.
              </li>
              <li>
                Corresponding bank statements showing payment of the salary.
              </li>
            </ul>
          </div>
        </div>
        <div className="category-b-card">
          <div className="category-b-card-number">03</div>
          <div className="category-b-card-content">
            <strong>Self-Employment Evidence :</strong>
            <ul>
              <li>Tax returns, such as SA302 or equivalent from HMRC.</li>
              <li>
                Audited accounts or unaudited accounts with an accountant’s
                certificate.
              </li>
              <li>
                Business bank statements covering the same 12-month period.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoryB;
