import React from "react";
import {
  FaFileAlt,
  FaHome,
  FaImages,
  FaEnvelope,
  FaUsers,
} from "react-icons/fa"; // Import icons
import "../../styles/relationship.css"; // Import CSS file

const RelationshipRequirements = () => {
  return (
    <div
      className="relationship-requirements-container my-5" // Updated class name
      id="relationship-requirements"
      data-aos="fade-up"
      data-aos-delay="200"
    >
      <h3 className="relationship-requirements-title mx-5 my-5">
        Relationship Requirements
      </h3>
      <div className="relationship-requirements-content">
        <p className="relationship-requirements-intro mx-5">
          To prove the genuineness and subsistence of your relationship, you
          will need:
        </p>
        <ol className="relationship-requirements-list mx-5">
          <li className="relationship-requirements-item">
            <FaFileAlt className="relationship-icon" />{" "}
            {/* Icon for document */}
            <strong>Marriage or Civil Partnership Certificate :</strong>
            <ul>
              <li>Official certificate of marriage or civil partnership.</li>
            </ul>
          </li>

          <li className="relationship-requirements-item">
            <FaHome className="relationship-icon" />{" "}
            {/* Icon for cohabitation */}
            <strong>Evidence of Cohabitation :</strong>
            <ul>
              <li>
                Joint utility bills, tenancy agreements, or mortgage documents
                showing shared accommodation.
              </li>
              <li>
                Bank statements or letters from official bodies addressed to
                both partners at the same address.
              </li>
            </ul>
          </li>

          <li className="relationship-requirements-item">
            <FaImages className="relationship-icon" /> {/* Icon for photos */}
            <strong>Photographs :</strong>
            <ul>
              <li>
                Photographs of your relationship, including the wedding, trips
                together, and family gatherings.
              </li>
            </ul>
          </li>

          <li className="relationship-requirements-item">
            <FaEnvelope className="relationship-icon" />{" "}
            {/* Icon for correspondence */}
            <strong>Correspondence :</strong>
            <ul>
              <li>
                Correspondence between you and your partner, such as emails,
                chat logs, and letters, showing regular contact over time.
              </li>
            </ul>
          </li>

          <li className="relationship-requirements-item">
            <FaUsers className="relationship-icon" /> {/* Icon for friends */}
            <strong>Statements from Friends and Family :</strong>
            <ul>
              <li>
                Letters from friends and family members attesting to your
                relationship.
              </li>
            </ul>
          </li>
        </ol>
      </div>
    </div>
  );
};

export default RelationshipRequirements;
