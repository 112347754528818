import React, { Fragment } from "react";
import HeroSection from "./hero-section";
import About from "./about";
// import Categories from "./categories";
import Services from "./services";
// import Teams from "./team";
// import FrequentlyAsked from "./frequently-asked";
import Contact from "./contact";

const Home = () => {
  return (
    <Fragment>
      <HeroSection />
      <About />
      <Services />
      <Contact />
      {/* <Categories />
      <Teams />
      <FrequentlyAsked />
       */}
    </Fragment>
  );
};

export default Home;
