import Aos from "aos";
import React, { useEffect } from "react";
import UnderstandingSpouseAndPartner from "../../components/understanding-spuse-and-partner";
import MinimumIncomeRequirement from "../../components/minimum-income-requirement";
import CategoryA from "../../components/category-a";
import CategoryB from "../../components/category-b";
import CategoryC from "../../components/category-c";
import CategoryD from "../../components/category-d";
import CategoryE from "../../components/category-e";
import CategoryF from "../../components/category-f";
import CategoryG from "../../components/category-g";
import AccommodationRequirements from "../../components/acommodation-requirements";
import RelationshipRequirements from "../../components/relationship-requirements";
import Acommodation from "../../components/acommodation";
import EnglishLanguageRequirements from "../../components/english-language";
import ImmigrationStatusDocuments from "../../components/immigration-status";
import AdditionalRequirement from "../../components/additional";
import HealthSurcharge from "../../components/health-surcharge";
import Tuberculosis from "../../components/tuberculosis";
import RequiremtSummary from "../../components/requirement-summary";

const VisaRequirements = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  }, []);

  useEffect(() => {
    Aos.init({
      duration: 800,
      easing: "slide",
      once: true,
      mirror: false,
    });

    return () => {
      Aos.refresh(); // Refresh AOS when the component unmounts
    };
  }, []);

  return (
    <section id="categories" class="about section mt-5">
      {/* <!-- Section Title --> */}
      <div class="container section-title" data-aos="fade-up">
        <span>
          SPOUSE AND PARTNER VISA REQUIREMENTS
          <br />
        </span>
        <h2>
          SPOUSE AND PARTNER VISA REQUIREMENTS
          <br />
        </h2>
        {/* <!-- <p>Welcome to our website, dedicated to providing advice and assistance to parents and guardians who --> */}
        {/* <!-- want to register their children as British citizens.</p> --> */}
      </div>
      {/* <!-- End Section Title --> */}

      <div class="">
        <UnderstandingSpouseAndPartner />

        <MinimumIncomeRequirement />

        <CategoryA />

        <CategoryB />

        <CategoryC />

        <CategoryD />

        <CategoryE />

        <CategoryF />

        <CategoryG />

        <AccommodationRequirements />

        <RelationshipRequirements />

        <Acommodation />

        <EnglishLanguageRequirements />

        <ImmigrationStatusDocuments />

        <AdditionalRequirement />

        <HealthSurcharge />

        <Tuberculosis />

        <RequiremtSummary />
      </div>
    </section>
  );
};

export default VisaRequirements;
