import React from "react";
import { FaFileAlt, FaGraduationCap, FaRegFilePdf } from "react-icons/fa"; // Import relevant icons
import "../../styles/englishLanguage.css"; // Import CSS file

const EnglishLanguageRequirements = () => {
  return (
    <div
      className="english-language-requirements-container my-5"
      id="english-language-requirements"
      data-aos="fade-up"
      data-aos-delay="200"
    >
      <h3 className="english-language-requirements-title mx-5 my-5">
        English Language Requirement
      </h3>
      <div className="english-language-requirements-content">
        <p className="english-language-requirements-intro mx-5">
          To prove that you meet the English language requirement, you will
          need:
        </p>
        <ol className="english-language-requirements-list mx-5">
          <li className="english-language-requirements-item">
            <FaFileAlt className="english-language-icon" />{" "}
            {/* Icon for test certificate */}
            <strong>English Language Test Certificate :</strong>
            <ul>
              <li>
                A certificate from an approved English language test provider
                showing you have passed an approved test at the required level.
              </li>
            </ul>
          </li>

          <li className="english-language-requirements-item">
            <FaGraduationCap className="english-language-icon" />{" "}
            {/* Icon for educational qualifications */}
            <strong>Educational Qualifications :</strong>
            <ul>
              <li>
                A degree certificate or academic transcript if your degree was
                taught in English and is recognized by UK NARIC.
              </li>
            </ul>
          </li>

          <li className="english-language-requirements-item">
            <FaRegFilePdf className="english-language-icon" />{" "}
            {/* Icon for exemption */}
            <strong>Exemption Evidence :</strong>
            <ul>
              <li>
                If you are exempt from the English language requirement, provide
                relevant documents such as medical reports or age proof (for
                applicants over 65).
              </li>
            </ul>
          </li>
        </ol>
      </div>
    </div>
  );
};

export default EnglishLanguageRequirements;
